// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

import axios from 'axios'

const config = useJwt.jwtConfig

const initialUser = () => {
    const item = window.localStorage.getItem('userData')
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: initialUser()
    },
    reducers: {
        handleLogin: async (state, action) => {
            state.userData = action.payload
            state[config.storageTokenKeyName] = action.payload[config.storageTokenKeyName]
            state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
            // eslint-disable-next-line no-use-before-define
            login(action.payload)
        },
        handleLogout: state => {
            state.userData = {}
            state[config.storageTokenKeyName] = null
            state[config.storageRefreshTokenKeyName] = null
            // ** Remove user, accessToken & refreshToken from localStorage
            localStorage.removeItem('userData')
            localStorage.removeItem(config.storageTokenKeyName)
            localStorage.removeItem(config.storageRefreshTokenKeyName)
        }
    }
})

const login = data => {
    axios
        .post('https://amp-cloud.demo-fsit.com:1337/api/v1/admin/login', {
            email: data.loginEmail,
            password: data.password
        })
        .then(res => {
            window.localStorage.setItem('userData', JSON.stringify(res.data.data[0]))
        })
}

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
